import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/const";
import AllbrightLogo from "../../images/allbrightLogo.svg";
import Heart from "../../images/heart.svg";
import Envelope from "../../images/envelope.svg";
import { FormattedMessage } from "gatsby-plugin-intl";

const Wrapper = styled.section`
  margin-bottom: 0;
  background: ${colors.transparentBlue};
  padding: 74px 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    color: ${colors.primaryDark};
    svg {
      margin-right: -0.2rem;
    }
    br {
      display: none;
    }
  }
  p {
    margin-bottom: 48px;
  }
  a {
    white-space: nowrap;
    margin-left: 0.8rem;
    color: ${colors.purple};
    text-decoration: none;
    box-shadow: 0 -3px inset ${colors.purple};
    &:hover {
      box-shadow: none;
    }
    svg {
      margin-right: 0.2rem;
    }
  }
  span {
    max-width: 559px;
    color: ${colors.grey};
    text-align: center;
    &:first-of-type {
      margin-bottom: 25px;
    }
    a {
      box-shadow: none;
    }
  }
  @media (max-width: 900px) {
    padding: 50px 5vmin;

    h2 {
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 20px;
      br {
        display: block;
      }
      svg {
        transform: translateY(6px);
      }
    }
    span {
      line-height: 28px;
    }
  }
`;

export function About() {
  return (
    <Wrapper>
      <p>
        <AllbrightLogo />
      </p>
      <h2>
        <FormattedMessage id="home_about_captio_is_made_with" /> <br />
        <Heart /> <br />
        <FormattedMessage id="home_about_by" />
        <a
          href="https://allbright.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="home_about_company_name" />
        </a>
      </h2>
      <span>
        <FormattedMessage id="home_about_we_are" />
      </span>
      <span>
        <FormattedMessage id="home_about_have_tips" />
        <br />
        <FormattedMessage id="home_about_just_feel" />
        <a
          href="mailto:hello@allbright.io"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Envelope /> <FormattedMessage id="home_about_email" />
        </a>
      </span>
    </Wrapper>
  );
}
