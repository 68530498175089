import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/const";

import FullSizeIcon from "../../images/full-size.svg";
import HalfScreenIcon from "../../images/minimize.svg";
import MinimizedTxtIcon from "../../images/minimizedTxt.svg";
import TextIcon from "../../images/TextIcon.svg";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { FormattedMessage } from "gatsby-plugin-intl";
import { withLineBreaks } from "../../utils/withLineBreaks";

export function Features() {
  const data = useStaticQuery(graphql`
    query {
      captureSettings: file(relativePath: { eq: "capture-settings.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1400, maxHeight: 900) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      captureFullSize: file(relativePath: { eq: "capture-full-size.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1400, maxHeight: 900) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      captureHalfScreen: file(relativePath: { eq: "capture-half-screen.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1400, maxHeight: 900) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      captureHalfText: file(relativePath: { eq: "capture-full-text.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1400, maxHeight: 900) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Wrapper>
      <Row reversed>
        <ColumnMaxWitdh reversed>
          <ColumnDescription>
            <div>
              <TextIcon />
              <h3>
                <FormattedMessage id="home_features_title1">
                  {withLineBreaks}
                </FormattedMessage>
              </h3>
              <span>
                <FormattedMessage id="home_features_message1" />
              </span>
            </div>
          </ColumnDescription>
          <ColumnImage>
            <Border>
              <ScreenImg
                fadeIn={false}
                durationFadeIn={0}
                objectFit="cover"
                fluid={data.captureSettings.childImageSharp.fluid}
              />
            </Border>
          </ColumnImage>
        </ColumnMaxWitdh>
      </Row>
      <Row>
        <ColumnMaxWitdh>
          <ColumnImage>
            <Border>
              <ScreenImg
                fadeIn={false}
                durationFadeIn={0}
                objectFit="cover"
                fluid={data.captureFullSize.childImageSharp.fluid}
              />
            </Border>
          </ColumnImage>
          <ColumnDescription>
            <div>
              <FullSizeIcon />
              <h3>
                <FormattedMessage id="home_features_title2">
                  {withLineBreaks}
                </FormattedMessage>
              </h3>
              <span>
                <FormattedMessage id="home_features_message2" />
              </span>
            </div>
          </ColumnDescription>
        </ColumnMaxWitdh>
      </Row>

      <Row reversed>
        <ColumnMaxWitdh reversed>
          <ColumnDescription>
            <div>
              <HalfScreenIcon />
              <h3>
                <FormattedMessage id="home_features_title3">
                  {withLineBreaks}
                </FormattedMessage>
              </h3>
              <span>
                <FormattedMessage id="home_features_message3" />
              </span>
            </div>
          </ColumnDescription>
          <ColumnImage>
            <Border>
              <ScreenImg
                fadeIn={false}
                durationFadeIn={0}
                objectFit="cover"
                fluid={data.captureHalfScreen.childImageSharp.fluid}
              />
            </Border>
          </ColumnImage>
        </ColumnMaxWitdh>
      </Row>

      <Row>
        <ColumnMaxWitdh>
          <ColumnImage>
            <Border>
              <ScreenImg
                fadeIn={false}
                durationFadeIn={0}
                objectFit="cover"
                fluid={data.captureHalfText.childImageSharp.fluid}
              />
            </Border>
          </ColumnImage>
          <ColumnDescription>
            <div>
              <MinimizedTxtIcon />
              <h3>
                <FormattedMessage id="home_features_title4">
                  {withLineBreaks}
                </FormattedMessage>
              </h3>
              <span>
                <FormattedMessage id="home_features_message4" />
              </span>
            </div>
          </ColumnDescription>
        </ColumnMaxWitdh>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  h2 {
    color: ${colors.primaryDark};
    text-align: center;
  }
`;

const ColumnMaxWitdh = styled.section`
  margin: 0 auto;
  display: Grid;
  grid-template-columns: ${props => (props.reversed ? "5fr 6fr" : "6fr 5fr")};
  grid-template-rows: 1fr;
  min-height: 663px;
  transform: ${props => (props.reversed ? "translateX(4%)" : "translate(-4%)")};
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    min-height: auto;
    transform: none;
  }
  @media (min-width: 1800px) {
    width: 1280px;
    transform: none;
  }
`;

const Row = styled.div`
  overflow: hidden;
  background: ${props => (props.reversed ? colors.transparentBlue : "white")};
`;

const ColumnDescription = styled.div`
  min-width: 370px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  h3 {
    color: ${colors.primaryDark};
    word-brake: no-break;
  }
  div {
    margin: auto;
    max-width: 367px;
    svg {
      margin-bottom: 19px;
    }
    span {
      color: ${colors.grey};
    }
  }
  @media (max-width: 900px) {
    order: 0;
    min-width: auto;
    padding: 50px 5vmin;
    span {
      line-height: 28px;
    }
  }
`;

const ColumnImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
  width: 100%;
  @media (max-width: 900px) {
    padding: 0 5vmin 50px 5vmin;
    order: 1;
    svg {
      max-width: 88vw;
      height: auto;
    }
  }
`;

const Border = styled.div`
  background: ${colors.lightGrey};
  padding: 31px 30px 34px 30px;
  border-radius: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.13);
  @media (max-width: 1250px) {
    padding: 1.2vmin;
    border-radius: 2vmin;
  }
  @media (max-width: 900px) {
    margin: 0 auto 10vmin auto;
    max-width: 90vw;
    padding: 2vmin;
    border-radius: 2vmin;
  }
`;

const ScreenImg = styled(Img)`
  border: ${colors.greyBlue} 2px solid;
  border-radius: 8px;
  width: 718px;
  height: 450px;
 @media (max-width: 1250px) {
  width: 40vw;
  height: calc(( 40vw - 2.4vmin ) / 1.59);
 }
  @media (max-width: 900px) {
    width: calc(100vw - 8vw - 28px);
    height calc((100vw - 8vw - 28px) / 1.59)
  }

`;
