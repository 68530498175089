import React from "react";
import styled from "styled-components";
import ManSittingWithComputer from "../../images/manSittingWithComputer.svg";
import Shape from "../../images/shape.svg";
import { FormattedMessage } from "gatsby-plugin-intl";
import { colors } from "../../utils/const";
import * as Scroll from "react-scroll";

const Wrapper = styled.section`
  position: relative;
  max-width: 1234px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  height: 100vh;
  min-height: 700px;
  @media (orientation: landscape) and (max-width: 900px) {
  }
`;

const PurpleButton = styled.button`
  background: rgba(131, 108, 242, 1);
  box-shadow: 0px 8px 12px rgba(131, 108, 242, 0.25);
  border-radius: 8px;
  color: white;
  font-weight: 300;
  min-width: 180px;
  padding: 0 1rem;
  height: 50px;
  transition: transform 0.2s ease-in;
  min-width: 220px;
  &:hover {
    cursor: pointer;
    background: rgba(131, 108, 242, 0.9);
  }
  &:active {
    transform: translateY(2px);
  }
`;

const Decoration = styled(Shape)`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0vw;
  max-width: 100vmin;
  @media (max-width: 600px) {
    top: -100vmin;
  }
`;
const IntroImage = styled(ManSittingWithComputer)`
  z-index: -1;
  bottom: 0;
  right: 0;
  min-width: 60vmin;
  min-height: 60vmin;
  margin-top: 1vmin;
  transform: scale(1.2) translate(-5%, 0%);
  @media (max-width: 900px) {
    transform: none;
    position: absolute;
    left: 20vmin;
    bottom: -29vmax;
    max-width: 100vmin;
  }
  @media (orientation: landscape) and (max-width: 900px) {
    bottom: -10vmax;
    left: 74vmin;
  }
`;
const RightColumn = styled.div`
  width: 50%;
  @media (orientation: landscape) and (max-width: 900px) {
    width: 0%;
  }
`;
const LeftColumn = styled.div`
  width: 50%;
  padding: 10px 14px;
  margin-top: 26vmin;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: start;
  flex-direction: column;
  span {
    margin-bottom: 38px;
    color: ${colors.grey};
  }
  h2 {
    margin-top: 2vh;
    text-align: start;
    color: ${colors.primaryDark};
    span {
      color: ${colors.purple};
      font-size: 48px;
      line-height: 68px;
      font-weight: 800;
    }
  }
  @media (max-width: 400px) {
    h2 {
      font-size: 10vmin;
      line-height: 12vmin;
      span {
        font-size: 10vmin;
        line-height: 12vmin;
      }
    }
  }
  @media (max-width: 600px) {
    justify-content: start;
    span {
      min-width: 90vw;
    }
  }
  @media (max-width: 900px) {
    padding: 10px 5vw;
    margin-top: 110px;
    h2 {
      max-width: auto;
      width: 220%;
    }
  }
  span {
    max-width: 500px;
  }
`;

export function Intro() {
  return (
    <div>
      <Decoration />
      <Wrapper>
        <LeftColumn>
          <h2>
            <FormattedMessage id="home_intro_slogan_start" />
            <span>
              <FormattedMessage id="home_intro_slogan_purple_accent" />
            </span>
            <FormattedMessage id="home_intro_slogan_end" />
          </h2>
          <span>
            <FormattedMessage id="home_intro_smaller_slogan" />
          </span>
          <Scroll.Link
            activeClass="active"
            to={"product"}
            spy={true}
            smooth={true}
            duration={300}
            offset={-50}
          >
            <PurpleButton>
              <FormattedMessage id="home_intro_purple_button" />
            </PurpleButton>
          </Scroll.Link>
        </LeftColumn>
        <RightColumn>
          <IntroImage />
        </RightColumn>
      </Wrapper>
    </div>
  );
}
