import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "react-multi-carousel/lib/styles.css";
import { FormattedMessage } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { colors } from "../../utils/const";
import { withLineBreaks } from "../../utils/withLineBreaks";

export function Benefits() {
  const data = useStaticQuery(graphql`
    query {
      Transcriptions: file(relativePath: { eq: "videoTranscriptions.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1224) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      Invitation: file(relativePath: { eq: "Invitation.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1224) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      FontsDislectic: file(relativePath: { eq: "fontDislectic.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1224) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const [currItem, setCurrItem] = useState(0);
  useEffect(() => {
    const interval = setInterval(function() {
      if (currItem === 0 || currItem === 1) {
        setCurrItem(currItem + 1);
      } else {
        setCurrItem(0);
      }
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  }, [currItem]);

  return (
    <Wrapper>
      <Column>
        <Title>
          <FormattedMessage id="home_benefits_title" />
        </Title>
        <SubTitle>
          <FormattedMessage id="home_benefits_subtitle">
            {withLineBreaks}
          </FormattedMessage>
        </SubTitle>

        <Row>
          <FirstColumn>
            <Border>
              {currItem === 0 && (
                <StyledImg
                  fadeIn={false}
                  fluid={data.Transcriptions.childImageSharp.fluid}
                />
              )}
              {currItem === 1 && (
                <StyledImg
                  fadeIn={false}
                  fluid={data.Invitation.childImageSharp.fluid}
                />
              )}
              {currItem === 2 && (
                <StyledImg
                  fadeIn={false}
                  fluid={data.FontsDislectic.childImageSharp.fluid}
                />
              )}
            </Border>
          </FirstColumn>

          <SecondColumn>
            <Button
              active={currItem === 0 ? 1 : 0}
              onClick={() => setCurrItem(0)}
            >
              <FormattedMessage id="home_benefits_video_with_transcription" />
            </Button>
            <Button
              active={currItem === 1 ? 1 : 0}
              onClick={() => setCurrItem(1)}
            >
              <FormattedMessage id="home_benefits_easy_sharing_link" />
            </Button>
            <Button
              active={currItem === 2 ? 1 : 0}
              onClick={() => setCurrItem(2)}
            >
              <FormattedMessage id="home_benefits_fonts_for_dislectics" />
            </Button>
          </SecondColumn>
        </Row>
        <MobileSlideTitle>
          <span>
            {currItem === 0 && (
              <FormattedMessage id="home_benefits_video_with_transcription" />
            )}
            {currItem === 1 && (
              <FormattedMessage id="home_benefits_easy_sharing_link" />
            )}
            {currItem === 2 && (
              <FormattedMessage id="home_benefits_fonts_for_dislectics" />
            )}
          </span>
        </MobileSlideTitle>
      </Column>
    </Wrapper>
  );
}

const MobileSlideTitle = styled.div`
  text-align: center;
  color: ${colors.primaryDark};
  margin-top: 14px;
  line-height: 150%;
  @media (min-width: 901px) {
    display: none;
  }
`;

const StyledImg = styled(Img)`
  border: ${colors.greyBlue} 2px solid;
  border-radius: 8px;
  width: 542px;
  height: 352px;
  @media (max-width: 900px) {
    margin: 0 auto 10vmin auto;
    max-width: 84vw;
    height:auto;
    padding: 2vmin;
    border-radius: 2vmin;
}
  }
`;

const Column = styled.div`
  margin: auto;
  max-width: 1280px;
`;

const Button = styled.button`
  text-align: left;
  margin: 10px;
  padding: 0 24px;
  max-width: 410px;
  width: 30vw;
  min-height: 74px;
  font-size: 18px;
  color: ${props => (props.active ? colors.purple : colors.grey)};
  font-weight: ${props => (props.active ? 500 : 400)};
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 6px #e4e7f2;
  border-radius: 6px;
  line-height: 48px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    font-weight: 500;
    color: ${colors.purple};
  }
  &:active {
    transform: translateY(2px);
  }
  @media (max-width: 1100px) {
    line-height: 28px;
  }
`;

const Row = styled.div`
  display: flex;
`;
const FirstColumn = styled.div`
  width: 50%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const SecondColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 50%;
  padding: 0 170px;
  @media (max-width: 900px) {
    display: none;
  }
  @media (max-width: 1100px) {
    padding: 0 145px;
    align-items: right;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #818895;
  max-width: 100%;
  margin: 0 auto 48px auto;
  @media (max-width: 900px) {
    margin: 0 auto 48px auto;
    line-height: 28px;
  }
`;

const Wrapper = styled.section`
  background: ${colors.transparentBlue};
  height: 729px;
  width: 100%;
  padding: 72px 15px 74px;
  @media (max-width: 900px) {
    padding: 50px 5vmin 50px;
    height: auto;
  }
`;

const Border = styled.div`
  background: ${colors.lightGrey};
  padding: 2vmin;
  border-radius: 2vmin;
  margin: auto;
  width: calc(540px + 4vmin);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.13);
  @media (max-width: 900px) {
    max-width: 88vmin;
    min-width: auto;
    height: 60vmin;
    max-height: calc(355px + 4vmin);
  }
`;
