import React, { useReducer, useState } from "react";
import styled from "styled-components";
// import { Button } from "../../components/button";
import { useIntl } from "gatsby-plugin-intl";
import { colors } from "../../utils/const";

const initialState = {
  name: { value: "", isValid: true },
  surname: { value: "", isValid: true },
  email: { value: "", isValid: true },
  phone: { value: "", isValid: true },
  question: { value: "", isValid: true }
};

function reducer(state, { field, value, isValid }) {
  return {
    ...state,
    [field]: { value: value, isValid: isValid }
  };
}

function validate(field, value) {
  switch (field) {
    case "name":
      return value.length > 2 ? true : false;
    case "surname":
      return value.length > 2 ? true : false;
    case "email":
      return value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        ? true
        : false;
    case "phone":
      const phoneNumberOnlyDigits = value.replace(/[^0-9]/g, "");
      return phoneNumberOnlyDigits.match(/[0-9]+/) &&
        phoneNumberOnlyDigits.length > 6
        ? true
        : false;
    case "question":
      return value.length > 2 ? true : false;
    default:
      return false;
  }
}

export function ContactForm() {
  const intl = useIntl();
  const t = x => {
    return intl.formatMessage({ id: x });
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [formValid, setFormValid] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [sendErr, setSendErr] = useState(false);

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  const checkIsFormValid = () => {
    Object.keys(state).every(
      item => state[item].isValid === true && state[item].value !== ""
    ) && setFormValid(true);
  };

  const checkInputsError = () => {
    Object.keys(state).forEach(item => {
      let value = state[item].value;
      let itemValid = validate(item, value);
      dispatch({ field: item, value, isValid: itemValid });
    });
  };

  const onChange = e => {
    const isValid = validate(e.target.name, e.target.value);
    dispatch({ field: e.target.name, value: e.target.value, isValid });
    checkIsFormValid();
  };

  const handleSubmit = e => {
    e.preventDefault();
    checkInputsError();
    checkIsFormValid();
    const formBody = JSON.parse(JSON.stringify(state));
    Object.keys(formBody).map(function(key) {
      formBody[key] = formBody[key].value;
    });

    if (formValid) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...formBody })
      })
        .then(setIsSend(true))
        .catch(error => {
          setSendErr(true);
          setTimeout(() => setSendErr(false), 5000);
        });
    } else {
      setSendErr(true);
      setTimeout(() => setSendErr(false), 5000);
    }
  };

  const { name, surname, email, phone, question } = state;

  return (
    <Wrapper>
      {isSend ? (
        <ThanksMassage>{t("contact_form_thank_you_message")}</ThanksMassage>
      ) : (
        <>
          <Form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <InputGroup>
              <Label error={name.isValid ? 0 : 1}>
                {t("contact_form_name")}
              </Label>
              <Input
                value={name.value}
                name="name"
                placeholder={t("contact_form_enter")}
                onChange={onChange}
              />
            </InputGroup>
            <InputGroup>
              <Label error={surname.isValid ? 0 : 1}>
                {t("contact_form_surname")}
              </Label>
              <Input
                value={surname.value}
                name="surname"
                placeholder={t("contact_form_enter")}
                onChange={onChange}
              />
            </InputGroup>
            <InputGroup>
              <Label error={email.isValid ? 0 : 1}>
                {t("contact_form_email")}
              </Label>
              <Input
                value={email.value}
                type="email"
                name="email"
                placeholder={t("contact_form_enter")}
                onChange={onChange}
              />
            </InputGroup>
            <InputGroup>
              <Label error={phone.isValid ? 0 : 1}>
                {t("contact_form_telephone")}
              </Label>
              <Input
                value={phone.value}
                type="tel"
                name="phone"
                placeholder={t("contact_form_enter")}
                onChange={onChange}
              />
            </InputGroup>
            <InputGroup isFull>
              <Label error={question.isValid ? 0 : 1}>
                {t("contact_form_question")}
              </Label>
              <Textarea
                value={question.value}
                name="question"
                placeholder={t("contact_form_message_content")}
                onChange={onChange}
              />
            </InputGroup>
            <SendBtn type="submit">{t("contact_form_send")}</SendBtn>
            <ErrorMsg sendErr={sendErr ? 1 : 0}>
              {t("contact_form_error_massage")}
            </ErrorMsg>
          </Form>
        </>
      )}
    </Wrapper>
  );
}

const ThanksMassage = styled.pre``;

const ErrorMsg = styled.span`
  margin: 10px 0;
  color: ${props => (props.sendErr ? "#FF5E52" : "transparent")};
  transition: 0.4s ease-in-out;
`;
const PurpleButton = styled.button`
  background: rgba(131, 108, 242, 1);
  box-shadow: 0px 8px 12px rgba(131, 108, 242, 0.25);
  border-radius: 8px;
  color: white;
  font-weight: 300;
  min-width: 180px;
  padding: 0 1rem;
  min-height: 50px;
  transition: transform 0.2s ease-in;
  &:hover {
    cursor: pointer;
    background: rgba(131, 108, 242, 0.9);
  }
  &:active {
    transform: translateY(2px);
  }
`;
const SendBtn = styled(PurpleButton)`
  margin-top: 40px;
  width: 200px;
  @media (max-width: 900px) {
    margin-bottom: 40px;
  }
`;

const Label = styled.label`
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  transition: 0.4s ease-in-out;
  color: ${props => (props.error ? "#FF5E52" : "#2E2F45")};
`;

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 696px;
  > div {
    display: flex;
    flex-direction: column;
  }
`;

const Input = styled.input`
  margin-right: 30px;
  height: 56px;
  padding: 18px;
  border-radius: 10px;
  border: 1px solid ${colors.grey};
  font-weight: 900;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #8f9fa9;
  }
`;

const InputGroup = styled.div`
  margin-top: 40px;
  max-width: ${props => (props.isFull ? "100%" : "328px")};
  width: ${props => (props.isFull ? "100%" : "50%")};
  @media (max-width: 900px) {
    max-width: 100%;
    width: 100%;
  }
`;

const Textarea = styled.textarea`
  margin-right: 30px;
  height: 150px;
  padding: 18px;
  border-radius: 10px;
  border: 1px solid ${colors.grey};
  font-weight: 900;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #8f9fa9;
  }
`;

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 0 24px;
`;
