import React from "react";

export const PrivacyPolicyEn = () => (
  <div>
    <h1>Privacy Policy for AllBright Technologies Sp. z o.o.</h1>
    <div className="content">
      <p>
        At AllBright.io, accessible from www.allbright.io, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by
        allbright.io and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>
      <h3>General Data Protection Regulation (GDPR)</h3>
      <p>We are a Data Controller of your information.</p>
      <p>
        AllBright Technologies Sp. z o.o. legal basis for collecting and using
        the personal information described in this Privacy Policy depends on the
        Personal Information we collect and the specific context in which we
        collect the information:
      </p>
      <ul>
        <li>
          AllBright Technologies Sp. z o.o. needs to perform a contract with you
        </li>
        <li>
          You have given AllBright Technologies Sp. z o.o. permission to do so
        </li>
        <li>
          Processing your personal information is in AllBright Technologies Sp.
          z o.o. legitimate interests
        </li>
      </ul>
      <p>
        AllBright Technologies Sp. z o.o. needs to comply with the law AllBright
        Technologies Sp. z o.o. will retain your personal information only for
        as long as is necessary for the purposes set out in this Privacy Policy.
        We will retain and use your information to the extent necessary to
        comply with our legal obligations, resolve disputes, and enforce our
        policies.
      </p>
      <p>
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. If you wish to be informed what Personal
        Information we hold about you and if you want it to be removed from our
        systems, please contact us. In certain circumstances, you have the
        following data protection rights:
      </p>
      <ul>
        <li>
          The right to access, update or to delete the information we have on
          you.
        </li>
        <li>The right of rectification.</li>
        <li>The right to object.</li>
        <li>The right of restriction.</li>
        <li>The right to data portability</li>
        <li>The right to withdraw consent</li>
      </ul>
      <h3>Log Files</h3>
      <p>
        AllBright.io follows a standard procedure of using log files. These
        files log visitors when they visit websites. All hosting companies do
        this and a part of hosting services' analytics. The information
        collected by log files include internet protocol (IP) addresses, browser
        type, Internet Service Provider (ISP), date and time stamp,
        referring/exit pages, and possibly the number of clicks. These are not
        linked to any information that is personally identifiable. The purpose
        of the information is for analyzing trends, administering the site,
        tracking users' movement on the website, and gathering demographic
        information.
      </p>
      <h3>Cookies and Web Beacons</h3>
      <p>
        Like any other website, allbright.io uses 'cookies'. These cookies are
        used to store information including visitors' preferences, and the pages
        on the website that the visitor accessed or visited. The information is
        used to optimize the users' experience by customizing our web page
        content based on visitors' browser type and/or other information.
      </p>
      <p>
        For more general information on cookies see the Wikipedia article on
        HTTP Cookies.
      </p>
      <h3>Privacy Policies</h3>
      <p>
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of allbright.io.
      </p>
      <p>
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on allbright.io, which are sent
        directly to users' browser. They automatically receive your IP address
        when this occurs. These technologies are used to measure the
        effectiveness of their advertising campaigns and/or to personalize the
        advertising content that you see on websites that you visit.
      </p>
      <p>
        Note that allbright.io has no access to or control over these cookies
        that are used by third-party advertisers.
      </p>
      <h3>Third Party Privacy Policies</h3>
      <p>
        AllBright.io's Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options..
      </p>
      <p>
        This site uses Google Analytics which is one of the most widespread and
        trusted analytics solution on the web for helping us to understand how
        you use the site and ways that we can improve your experience. These
        cookies may track things such as how long you spend on the site and the
        pages that you visit so we can continue to produce engaging content. For
        more information on Google Analytics cookies, see the official Google
        Analytics page.
      </p>
      <p>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites
      </p>
      <h3>Children's Information</h3>
      <p>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </p>
      <p>
        AllBright.io does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
      </p>
      <h3>Online Privacy Policy Only</h3>
      <p>
        Our Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in AllBright.io. This policy is not applicable to
        any information collected offline or via channels other than this
        website.
      </p>
      <h3>Consent</h3>
      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>
    </div>
  </div>
);

export const PrivacyPolicyPl = () => (
  <div>
    <h1>Privacy Policy for AllBright Technologies Sp. z o.o.</h1>
    <div className="content">
      <p>
        At AllBright.io, accessible from www.allbright.io, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by
        allbright.io and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>
      <h3>General Data Protection Regulation (GDPR)</h3>
      <p>We are a Data Controller of your information.</p>
      <p>
        AllBright Technologies Sp. z o.o. legal basis for collecting and using
        the personal information described in this Privacy Policy depends on the
        Personal Information we collect and the specific context in which we
        collect the information:
      </p>
      <ul>
        <li>
          AllBright Technologies Sp. z o.o. needs to perform a contract with you
        </li>
        <li>
          You have given AllBright Technologies Sp. z o.o. permission to do so
        </li>
        <li>
          Processing your personal information is in AllBright Technologies Sp.
          z o.o. legitimate interests
        </li>
      </ul>
      <p>
        AllBright Technologies Sp. z o.o. needs to comply with the law AllBright
        Technologies Sp. z o.o. will retain your personal information only for
        as long as is necessary for the purposes set out in this Privacy Policy.
        We will retain and use your information to the extent necessary to
        comply with our legal obligations, resolve disputes, and enforce our
        policies.
      </p>
      <p>
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. If you wish to be informed what Personal
        Information we hold about you and if you want it to be removed from our
        systems, please contact us. In certain circumstances, you have the
        following data protection rights:
      </p>
      <ul>
        <li>
          The right to access, update or to delete the information we have on
          you.
        </li>
        <li>The right of rectification.</li>
        <li>The right to object.</li>
        <li>The right of restriction.</li>
        <li>The right to data portability</li>
        <li>The right to withdraw consent</li>
      </ul>
      <h3>Log Files</h3>
      <p>
        AllBright.io follows a standard procedure of using log files. These
        files log visitors when they visit websites. All hosting companies do
        this and a part of hosting services' analytics. The information
        collected by log files include internet protocol (IP) addresses, browser
        type, Internet Service Provider (ISP), date and time stamp,
        referring/exit pages, and possibly the number of clicks. These are not
        linked to any information that is personally identifiable. The purpose
        of the information is for analyzing trends, administering the site,
        tracking users' movement on the website, and gathering demographic
        information.
      </p>
      <h3>Cookies and Web Beacons</h3>
      <p>
        Like any other website, allbright.io uses 'cookies'. These cookies are
        used to store information including visitors' preferences, and the pages
        on the website that the visitor accessed or visited. The information is
        used to optimize the users' experience by customizing our web page
        content based on visitors' browser type and/or other information.
      </p>
      <p>
        For more general information on cookies see the Wikipedia article on
        HTTP Cookies.
      </p>
      <h3>Privacy Policies</h3>
      <p>
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of allbright.io.
      </p>
      <p>
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on allbright.io, which are sent
        directly to users' browser. They automatically receive your IP address
        when this occurs. These technologies are used to measure the
        effectiveness of their advertising campaigns and/or to personalize the
        advertising content that you see on websites that you visit.
      </p>
      <p>
        Note that allbright.io has no access to or control over these cookies
        that are used by third-party advertisers.
      </p>
      <h3>Third Party Privacy Policies</h3>
      <p>
        AllBright.io's Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options..
      </p>
      <p>
        This site uses Google Analytics which is one of the most widespread and
        trusted analytics solution on the web for helping us to understand how
        you use the site and ways that we can improve your experience. These
        cookies may track things such as how long you spend on the site and the
        pages that you visit so we can continue to produce engaging content. For
        more information on Google Analytics cookies, see the official Google
        Analytics page.
      </p>
      <p>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites
      </p>
      <h3>Children's Information</h3>
      <p>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </p>
      <p>
        AllBright.io does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
      </p>
      <h3>Online Privacy Policy Only</h3>
      <p>
        Our Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in AllBright.io. This policy is not applicable to
        any information collected offline or via channels other than this
        website.
      </p>
      <h3>Consent</h3>
      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>
    </div>
  </div>
);
