import React from "react";
import styled from "styled-components";
import WebRTC from "../../images/webRTC.svg";
import XMPP from "../../images/XMPP.svg";
import SpeachToText from "../../images/speachToText.svg";
import ReactLogo from "../../images/reactLogo.svg";
import { FormattedMessage } from "gatsby-plugin-intl";
import { withLineBreaks } from "../../utils/withLineBreaks";

export function Technology() {
  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="home_tech_title" />
      </Title>
      <SubTitle>
        <FormattedMessage id="home_tech_message">
          {withLineBreaks}
        </FormattedMessage>
      </SubTitle>
      <TechnologyRow>
        <WebRTC />
        <XMPP />
        <SpeachToText />
        <ReactLogo />
      </TechnologyRow>
    </Wrapper>
  );
}

const TechnologyRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  svg {
    margin: auto;
    &:nth-of-type(1) {
      width: 112px;
    }
    &:nth-of-type(2) {
      width: 150px;
    }
    &:nth-of-type(3) {
      width: 121px;
    }
    &:nth-of-type(4) {
      width: 132px;
    }
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    svg {
      transform: scale(0.7);
    }
  }
`;

const Wrapper = styled.section`
  padding-top: 74px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 481px;
  @media (max-width: 900px) {
    padding: 46px 5vmin;
    height: auto;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #818895;
  margin: 0 auto 24px auto;
  @media (max-width: 900px) {
    margin-bottom: 30px;
    line-height: 28px;
  }
`;
