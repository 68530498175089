import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/const";
import { FormattedMessage } from "gatsby-plugin-intl";
import chromeIcon from "../../images/chromeBox.svg";
import mobileSmartphone from "../../images/smartphone.svg";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Wrapper = styled.section`
  max-width: 100vw;
  margin-bottom: 0;
  margi-top: 100px;
  background: linear-gradient(white 50%, ${colors.transparentBlue} 50%);
  > div {
    padding: 0 5vmin;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
`;

const PurpleBox = styled.div`
  position: relative;
  min-height: 587px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: ${colors.lightPurple};
  border-radius: 24px;
  width: calc(50% - 15px);
  max-width: 587px;
  margin: 15px;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    min-height: 650px;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    padding: 0;
  }
`;

const ChromeIcon = styled(chromeIcon)`
  margin-bottom: 45px;
`;
const MobileSmartphone = styled(mobileSmartphone)`
  margin-bottom: 40px;
`;

const InfoBox = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.primaryDark};
  position: absolute;
  left: 15%;
  top: 0;
  h2 {
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 48px;
  }
  span {
    font-size: 18px;
    line-height: 32px;
    display: inline-block;
    text-align: center;
    margin-bottom: 27px;
    color: ${colors.grey};
  }

  @media (max-width: 900px) {
    left: 0;
    top: 0;
    width: 100%;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    h2 {
      text-align:start;
      font-size: 36px;
      min-width: auto;
      max-width: calc(100vw - 64px);
    }
    span {
      text-align:start;
      font-size: 22px;
      ine-height: 28px
      min-width: auto;
      margin-bottom: 16px;
      max-width: calc(100vw - 64px);
    }
  }
`;
const DecoratorWrapper = styled.div`
  min-height: 587px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    min-height: 650px;
    align-items: flex-start;
  }
`;

const Decorator = styled(Img)`
  opacity: 0.1;
  width: 80%;
  height: 60%;
  @media (max-width: 900px) {
    width: 95%;
  }
`;

const PurpleButton = styled.button`
  background: rgba(131, 108, 242, 1);
  box-shadow: 0px 8px 12px rgba(131, 108, 242, 0.25);
  border-radius: 8px;
  color: white;
  font-weight: 300;
  min-width: 180px;
  padding: 0 1rem;
  min-height: 50px;
  transition: transform 0.2s ease-in;
  &:hover {
    cursor: pointer;
    background: rgba(131, 108, 242, 0.9);
  }
  &:active {
    transform: translateY(2px);
  }
`;

const BlackButtonImitationRow = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const BlackButtonImitation = styled(Img)`
  width: 162px;
  margin: 0 7.5px;
  @media (max-width: 900px) {
    margin: 10px 0;
  }
`;

export function TryOurApp() {
  const data = useStaticQuery(graphql`
    query {
      CaptioIconHugeGradient: file(
        relativePath: { eq: "iconHugeGradient.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      AppStore: file(relativePath: { eq: "AppStore.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      GooglePlay: file(relativePath: { eq: "GooglePlay.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <div>
        <PurpleBox>
          <DecoratorWrapper>
            <Decorator
              fadeIn={false}
              fluid={data.CaptioIconHugeGradient.childImageSharp.fluid}
            />
            <InfoBox>
              <ChromeIcon />
              <h2>
                <FormattedMessage id="home_try_our_app_title1" />
              </h2>
              <span>
                <FormattedMessage id="home_try_our_app_description1" />
              </span>
              {/* Comment out untill release */}
              {/* <a
                href="https://app.captio.cc/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <PurpleButton>
                  <FormattedMessage id="home_try_our_app_button" />
                </PurpleButton>
              </a> */}
            </InfoBox>
          </DecoratorWrapper>
        </PurpleBox>
        <PurpleBox>
          <DecoratorWrapper>
            <Decorator
              fadeIn={false}
              fluid={data.CaptioIconHugeGradient.childImageSharp.fluid}
            />
            <InfoBox>
              <MobileSmartphone />
              <h2>
                <FormattedMessage id="home_try_our_app_title2" />
              </h2>
              <span>
                <FormattedMessage id="home_try_our_app_description2" />
              </span>
              <BlackButtonImitationRow>
                <BlackButtonImitation
                  fadeIn={false}
                  fluid={data.AppStore.childImageSharp.fluid}
                />
                <BlackButtonImitation
                  fadeIn={false}
                  fluid={data.GooglePlay.childImageSharp.fluid}
                />
              </BlackButtonImitationRow>
            </InfoBox>
          </DecoratorWrapper>
        </PurpleBox>
      </div>
    </Wrapper>
  );
}
