import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/const";
import ManPresenting from "../../images/manPresenting.svg";
import WomanSitting from "../../images/womanSitting.svg";
import WomanDesk from "../../images/womanDesk.svg";
import { FormattedMessage } from "gatsby-plugin-intl";
import { withLineBreaks } from "../../utils/withLineBreaks";

const Wrapper = styled.section`
  overflow: hidden;
  width: 100%;
  max-width: 1280px;
  margin: 74px auto 0 auto;
  h2 {
    margin: auto auto 23px auto;
    max-width: 481px;
    color: ${colors.primaryDark};
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 20px;
    b {
      font-weight: 400;
      color: ${colors.purple};
    }
  }
  span {
    max-width: 1000px;
    display: block;
    text-align: center;
    margin: auto;
    color: ${colors.grey};
  }
  @media (max-width: 900px) {
    margin: 50px auto;
    padding: 0 5vmin;
    h2 {
      font-size: 36px;
      line-height: 48px;
    }
    span {
      line-height: 28px;
    }
  }
`;

const Figure = styled.figure`
  padding: 0 14px;
  min-width: 319px;
  min-height: 368px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  h3 {
    margin-top: 30px;
    color: ${colors.primaryDark};
    text-align: center;
    font-weight: 300;
    font-size: 24px;
    line-height: 68px;
    margin-bottom: 0;
  }
  span {
    color: ${colors.grey};
    display: block;
    text-align: center;
    min-height: 94px;
  }
  svg {
    height: 20vmin;
    min-width: 20vmin;
  }
  @media (max-width: 1000px) {
    min-height: auto;
    padding: 0;
    min-width: auto;
    svg {
      min-width: 60vw;
      min-height: 50vmin;
      max-width: 90vmin;
    }
  }
`;

const RectanglePlaceholderRow = styled.div`
  max-width: 1280px;
  display: Grid;
  margin: 48px auto 74px auto;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1000px) {
    grid-row-gap: 10vmin;
    grid-template-columns: 1fr;
  }
  @media (max-width: 900px) {
    margin: 10vmin auto 10vmin auto;
  }
`;

export function HowItWorks() {
  return (
    <Wrapper>
      <h2>
        <FormattedMessage id="home_communicate_easier_title" />
      </h2>
      <span>
        <FormattedMessage id="home_communicate_easier_subtitle">
          {withLineBreaks}
        </FormattedMessage>
      </span>
      <RectanglePlaceholderRow>
        <Figure>
          <ManPresenting />
          <figcaption>
            <h3>
              <FormattedMessage id="home_communicate_easier_figure1_title" />
            </h3>
            <span>
              <FormattedMessage id="home_communicate_easier_figure1_message" />
            </span>
          </figcaption>
        </Figure>
        <Figure>
          <WomanSitting />
          <figcaption>
            <h3>
              <FormattedMessage id="home_communicate_easier_figure2_title" />
            </h3>
            <span>
              <FormattedMessage id="home_communicate_easier_figure2_message" />
            </span>
          </figcaption>
        </Figure>
        <Figure>
          <WomanDesk />
          <figcaption>
            <h3>
              <FormattedMessage id="home_communicate_easier_figure3_title" />
            </h3>
            <span>
              <FormattedMessage id="home_communicate_easier_figure3_message" />
            </span>
          </figcaption>
        </Figure>
      </RectanglePlaceholderRow>
    </Wrapper>
  );
}
