import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { colors } from "../../utils/const";
import { FormattedMessage } from "gatsby-plugin-intl";
import { withLineBreaks } from "../../utils/withLineBreaks";

export function TeamBehindProject() {
  const data = useStaticQuery(graphql`
    query {
      allbrightMarek: file(relativePath: { eq: "allbrightMarek.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 300, maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      allbrightPiotr: file(relativePath: { eq: "allbrightPiotr.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 300, maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="home_team_title" />
      </Title>
      <SubTitle>
        <FormattedMessage id="home_team_subtitle">
          {withLineBreaks}
        </FormattedMessage>
      </SubTitle>
      <PhotoRow>
        <TeamMember>
          <ProfileImg
            fadeIn={false}
            fluid={data.allbrightMarek.childImageSharp.fluid}
          />
          <figcaption>
            <span>
              <FormattedMessage id="home_team_cto_name" />
            </span>
            <span>
              <FormattedMessage id="home_team_cto" />
            </span>
          </figcaption>
        </TeamMember>
        <TeamMember>
          <ProfileImg
            fadeIn={false}
            fluid={data.allbrightPiotr.childImageSharp.fluid}
          />
          <figcaption>
            <span>
              <FormattedMessage id="home_team_po_name" />
            </span>
            <span>
              <FormattedMessage id="home_team_po" />
            </span>
          </figcaption>
        </TeamMember>
      </PhotoRow>
    </Wrapper>
  );
}

const TeamMember = styled.figure`
  max-width: 314px;
  margin: 0 100px;
  figcaption {
    margin: 0 18px;
    display: flex;
    flex-direction: column;
    span:nth-of-type(1) {
      font-size: 36px;
      line-height: 133%;
      display: flex;
      width: 130%;
      color: ${colors.primaryDark};
    }
    span:nth-of-type(2) {
      font-size: 18px;
      line-height: 133%;
      color: ${colors.grey};
    }
  }
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      text-align: center;
    }
    figcaption span:nth-of-type(1) {
      font-size: 24px;
    }
    figcaption:nth-of-type(1) {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 900px) {
    margin: 0;
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  background: rgba(243, 246, 255, 0.4);
  height: auto;
  padding: 74px 0 65px 0;
  @media (max-width: 900px) {
    padding: 50px 5vmin;
  }
`;

const ProfileImg = styled(Img)`
  margin: 0 18px 24px 18px;
  width: 278px;
  height: 278px;
  max-width: 50vmin;
  max-height: 50vmin;
  border-radius: 24px;
`;

const PhotoRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Title = styled.h2`
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #818895;
  max-width: 1000px;
  margin: 0 auto 48px auto;
  div {
    text-align: start;
  }
  div:nth-of-type(1) {
    font-style: italic;
    text-align: center;
    width: 90%;
    margin: auto;
  }

  @media (max-width: 900px) {
    div {
      margin: auto;
    }
    div:nth-of-type(1) {
      width: 90%;
      text-align: start;
    }
    margin: 0 auto 50px auto;
    text-align: start;
    max-width: 90vw;
    line-height: 28px;
  }
`;
